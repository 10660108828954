<template>
  <div>
    <nav-bar>
      <div class="edition-center">
        <div class="page-container">
          <div class="flex items-center justify-between">
            <div class="title-bar">Información del pedido</div>
            <div class="title_right flex items-center" @click="$refs.progressRef.open(orderDetail)">Verificar el progreso del pedido

              <img src="@/assets/icons/right-arrow.png" style="width: 7px; height: 12px; margin-left: 7px" />
            </div>
          </div>
          <div class="order-bar">
            <img src="@/assets/order/line.png" alt="" />
            <div class="order-info">
              <div class="order-info-left">
                <div
                  style="margin-bottom: 20px"
                  v-if="orderDetail.distributionType != 'SELF'"
                >
                  <div class="title">la dirección de cosecha:</div>
                  <div>
                    <p>
                      {{ orderDetail.shipUsername }}
                      {{ orderDetail.shipMobile }}
                    </p>
                    <p style="color: #979797">{{ orderDetail.shipAddress }}</p>
                  </div>
                </div>
                <div>
                  <div class="title">Observaciones del pedido:</div>
                  <div>
                    <p>{{ orderDetail.remark }}</p>
                  </div>
                </div>
              </div>
              <div class="order-info-right">
                <div>
                  <div>Generar un comprobante：</div>
                  <div>
                    {{ certificateType[orderDetail.billingCertificateType] }}
                  </div>
                </div>
                <div>
                  <div>Método de pago：</div>
                  <div>
                    {{ settlementType[orderDetail.settlementType - 1] }}
                  </div>
                </div>
                <div>
                  <div>Número de pedidos：</div>
                  <div>{{ orderDetail.goodsNums }} unidades</div>
                </div>
                <div>
                  <div>Cantidad real entregada：</div>
                  <div>{{ orderDetail.deliveryNumber }} unidades</div>
                </div>
                <div>
                  <div>Número de pedido:</div>
                  <div>{{ orderDetail.id }}</div>
                </div>
                <div>
                  <div>Fecha de creación:</div>
                  <div>{{ orderDetail.createdTime }}</div>
                </div>
                <div>
                  <div>Vendedor/Vendedora:</div>
                  <div>
                    {{
                      orderDetail.salesEmployeeDetail
                        ? orderDetail.salesEmployeeDetail.employeeName +
                          ' ' +
                          orderDetail.salesEmployeeDetail.employeePhone
                        : ''
                    }}
                  </div>
                </div>
                <!-- 客服电话 -->
                <div class="">
                  <div>Línea directa al consumidor：</div>
                  <div v-if="orderDetail.customerServiceDetail">
                    {{ orderDetail.customerServiceDetail.employeeName }}
                    {{ orderDetail.customerServiceDetail.employeePhone }}
                  </div>
                </div>
              </div>
            </div>

            <img src="@/assets/order/line.png" alt="" />
          </div>

          <!-- 订单表格 ----------------------------------- -->
          <div class="order-table">
            <div class="header">
              <div style="flex: 3">Productos Básicos</div>
              <div>Precio unitario</div>
              <div>Cantidad</div>
              <div>Cantidad entregada</div>
              <div>Subtotal</div>
              <div>Total</div>
              <div style="padding: 0 15px">
                Operaciones de productos básicos
              </div>
              <div>Operaciones</div>
            </div>
            <div class="order-list">
              <div class="order-item">
                <div class="item-header">
                  <div>{{ orderDetail.orderNum }}</div>
                  <div>
                    <div
                      style="
                        color: #8acf66;
                        display: inline-block;
                        margin-right: 15px;
                      "
                    >
                      {{ statusMap[orderDetail.orderStatus] }}
                    </div>
                    <span>
                      {{
                        orderDetail.createdTime &&
                        orderDetail.createdTime.split(' ')[0]
                      }}
                    </span>
                  </div>
                </div>
                <div class="item-content">
                  <div class="item-goods">
                    <div
                      class="item-goods-list"
                      v-for="(goods, goodIndex) in orderDetail.orderGoods"
                      :key="goodIndex"
                    >
                      <div class="item-goods-flex">
                        <div style="flex: none; margin-right: 20px">
                          <div class="list-item">
                            <div class="item-img">
                              <img
                                :src="
                                  filterCoverUrl(goods.goodsCoverUrl)
                                    | filterPicture
                                "
                                alt=""
                              />
                            </div>
                            <div class="item-text">
                              <div class="item-name">
                                <span class="goods-sn">
                                  {{ goods.goodsSn }}
                                </span>
                                <span>{{ goods.goodsName }}</span>
                              </div>
                              <div class="specs">
                                <span
                                  v-for="(item, index) in goods.specs"
                                  :key="index"
                                >
                                  {{ (index === 0 ? '' : ' / ') + item.name }} :
                                  {{ item.value }}
                                </span>
                              </div>
                              <div class="barCode">
                                Código de barras：{{ goods.skuBarCode }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="price">
                          COP {{ goods.goodsPrice | filterPrice }}
                        </div>
                        <div>{{ goods.buyNums }}</div>
                        <div>{{ goods.deliveryNumber }}</div>
                        <div class="price">
                          COP {{ goods.buyPriceOriginal | filterPrice }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="price">
                    <div class="alignment">
                      COP {{ orderDetail.buyPricePay | filterPrice }}
                    </div>
                  </div>
                  <div>
                    <div
                      class="order-status"
                      v-for="(goods, goodIndex) in orderDetail.orderGoods"
                      :key="goodIndex"
                    >
                      <div
                        v-if="
                          orderDetail.orderStatus === 'RECEIVE' &&
                          !goods.afterSaleStatus
                        "
                      >
                        <el-button
                          v-if="!goods.comment"
                          type="text"
                          @click="toEvaluate(goods)"
                        >
                          Comentario
                        </el-button>
                        <el-button
                          type="text"
                          style="color: #979797"
                          @click="toAfterSale(goods.id)"
                        >
                          Solicitud de reembolso
                        </el-button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="alignment">
                      <div>
                        <!-- 去付款 -->
                        <el-button
                          v-if="orderDetail.orderStatus === 'UNPAY'"
                          type="text"
                          round
                          style="margin: 3px"
                          color="#8ACF66"
                          @click="toPay"
                        >
                          Ir a pagar
                        </el-button>
                        <el-button
                          v-if="
                            orderDetail.orderStatus === 'DELIVERY' &&
                            (orderDetail.settlementType == '2' ||
                              orderDetail.settlementType == '3')
                          "
                          type="text"
                          round
                          style="margin: 3px"
                          color="#8ACF66"
                          @click="toPay"
                        >
                          Ir a pagar
                        </el-button>
                        <!-- 确认收货 -->
                        <el-button
                          v-if="
                            orderDetail.orderStatus === 'DELIVERY' &&
                            !orderAfterStatus &&
                            orderDetail.settlementType == '1'
                          "
                          type="text"
                          round
                          style="margin: 3px"
                          color="#979797"
                          @click="receiveOrder"
                        >
                          Confirmar recepción
                        </el-button>
                        <!-- 评价 -->
                        <el-button
                          v-if="
                            orderDetail.orderStatus === 'RECEIVE' &&
                            !orderAfterStatus
                          "
                          type="text"
                          round
                          style="margin: 3px"
                          color="#8ACF66"
                          @click="toEvaluate"
                        >
                          Comentario a todos
                        </el-button>
                        <!-- 删除订单 -->
                        <el-button
                          v-if="
                            (orderDetail.orderStatus === 'RECEIVE' ||
                              orderDetail.orderStatus === 'COMMENT' ||
                              orderDetail.orderStatus === 'CLOSE') &&
                            !orderAfterStatus
                          "
                          type="text"
                          round
                          style="margin: 3px; color: #d43a57"
                          color="#d43a57"
                          @click="delOrder"
                        >
                          Eliminar pedido
                        </el-button>
                        <!-- 申请售后 -->
                        <el-button
                          v-if="
                            orderDetail.orderStatus === 'RECEIVE' &&
                            !orderAfterStatus
                          "
                          type="text"
                          round
                          style="margin: 3px; color: #979797"
                          @click="toAfterSale()"
                        >
                          Solicitud de reembolso
                        </el-button>
                        <!-- 修改申请 -->
                        <!-- <el-button v-if="orderAfterStatus === 'AFTERSALE'" type="text" round style="margin: 3px " color="#8ACF66">Modificar la solicitud</el-button> -->
                        <!-- 取消订单 -->
                        <el-button
                          v-if="orderDetail.orderStatus === 'CONFIRM'"
                          type="text"
                          round
                          style="margin: 3px"
                          color="#979797"
                          @click="cancelOrder"
                        >
                          Cancelar pedido
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ----------------------------------- -->

          <div class="price-container">
            <p>
              <span class="label">Subtotal: COP</span>
              <span class="value" style="font-size: 20px">
                {{ orderDetail.priceOriginal | filterPrice }}
              </span>
            </p>
            <p>
              <span class="label">Descuento: COP</span>
              <span class="value">
                {{ orderDetail.pricePreferentialShop | filterPrice }}
              </span>
            </p>
            <p>
              <span class="label">IVA: COP</span>
              <span class="value">
                {{ orderDetail.taxPoint | filterPrice }}
              </span>
            </p>
            <p>
              <span class="label">Flete: COP</span>
              <span class="value">
                {{ orderDetail.priceDelivery | filterPrice }}
              </span>
            </p>
            <p style="font-weight: bold">
              <span class="label">Total:</span>
              <span class="value" style="color: #d43a57">
                COP {{ orderDetail.buyPricePay | filterPrice }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </nav-bar>
    <the-progress-dialog ref="progressRef" />
  </div>
</template>

<script>
import navBar from '@/components/navBar/index.vue'
import TheProgressDialog from './components/The-progress-dialog.vue'
export default {
  components: {
    navBar,
    TheProgressDialog
  },

  data() {
    return {
      orderDetail: [],
      certificateType: {
        RECEIPT: 'Recibos',
        INVOICE: 'Facturas (19% IVA)'
      },
      settlementType: [
        'Pago inmediato',
        'Pago contra entrega',
        'Pago con crédito'
      ],
      statusMap: {
        UNPAY: 'Pedido listo',
        PAY: 'Envío pendiente',
        DELIVERY: 'Pendiente por recibir',
        RECEIVE: 'Pendiente por comentarios',
        COMMENT: 'Completados',
        CANCEL: 'Pedido cerrado',
        CLOSE: 'Pedido cerrado',
        REVIEW: 'Revisión pendiete',
        CONFIRM: 'Confirmación  pendiente'
      },
      orderAfterStatus: ''
    }
  },

  created() {
    this.getOrderDetail()
  },

  methods: {
    getOrderDetail() {
      this.$axios.post('/childOrder/detail/' + this.$route.params.id).then((res) => {
        this.orderDetail = res.data.result
        console.log('订单详情-', this.orderDetail)
        this.orderDetail.orderGoods.map((good) => {
          // 如果售后拓展存在数据，则判断该订单中商品对应的售后状态
          if (this.orderDetail.orderAftersales) {
            this.orderDetail.orderAftersales.map((afterSaleGood) => {
              if (afterSaleGood.orderGoodsId === good.id) {
                console.log('找到了')
                this.$set(good, 'afterSaleStatus', true)
              }
            })

            if (
              this.orderDetail.orderAftersales.length ===
              this.orderDetail.orderGoods.length
            ) {
              this.orderAfterStatus = true
            }
          }
          good.specs = JSON.parse(good.goodsSpecs)
          return good
        })
      })
    },

    receiveOrder() {
      this.$confirm('¿Has confirmado la recepción?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((res) => {
        this.$axios
          .post('/order/receiveOrder', { orderId: this.orderDetail.id })
          .then((res) => {
            this.$message.success('Confirmar el éxito de la recepción')
            setTimeout(() => {
              this.getOrderDetail()
            }, 2000)
          })
      })
    },

    toPay() {
      this.$router.push({
        path: '/pay',
        name: 'pay',
        params: {
          orderId: this.orderDetail.id,
          totalPrice: this.orderDetail.pricePay,
          billingCertificateType: this.orderDetail.billingCertificateType
        }
      })
    },

    toEvaluate(item) {
      this.$router.push({
        path: '/evaluate',
        name: 'evaluate',
        params: { orderId: this.orderDetail.id, orderGoodsId: item.id }
      })
    },

    toAfterSale(orderGoodsId = '') {
      let idArr = []
      // 判断单个商品售后还是多个商品售后, 没传参数是多商品
      if (!orderGoodsId) {
        for (let i = 0; i < this.orderDetail.orderGoods.length; i++) {
          // 如果商品没有处于售后状态才会push进路由参数
          if (!this.orderDetail.orderGoods[i].afterSaleStatus) {
            let goodsId = this.orderDetail.orderGoods[i].id
            idArr.push(goodsId)
          }
        }
      } else {
        idArr.push(orderGoodsId)
      }
      console.log(idArr)
      this.$router.push({
        path: '/refund-apply',
        name: 'refund-apply',
        params: { orderGoodsId: idArr }
      })
    },

    delOrder() {
      //您确定删除订单
      this.$confirm('¿Deseas cancelar el pedido?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((res) => {
        this.$axios
          .post('/order/delete', { id: this.orderDetail.id })
          .then((res) => {
            this.$message.success('El pedido fue eliminada') //订单已删除
            setTimeout(() => {
              this.$router.push('/mine')
            }, 2000)
          })
      })
    },

    cancelOrder() {
      //您确定取消订单
      this.$confirm('¿Deseas cancelar el pedido?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((res) => {
        this.$axios
          .post('/order/cancelOrder', { orderId: this.orderDetail.id })
          .then((res) => {
            this.$message.success('El pedido fue cancelado') //订单已取消
            setTimeout(() => {
              this.getOrderDetail()
            }, 2000)
          })
      })
    },
    filterCoverUrl(coverUrl) {
      return JSON.parse(coverUrl)[0].url
    },
    /**
     * @description: 查看进度
     * @return {*}
     */
    lookAtTheProgress() {

    }
  }
}
</script>

<style lang="scss" scoped>
img {
  display: block;
}

.edition-center {
  width: 1200px;
  margin: 0 auto;
}

.page-container {
  margin: 40px 0;
  .title-bar {
    font-size: 20px;
    font-weight: bold;
  }
  .title_right {
    height: 27px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606060;
    line-height: 27px;
  }
  .order-bar {
    margin-top: 20px;
    img {
      width: 100%;
    }
    .order-info {
      display: flex;
      .order-info-left {
        width: 360px;
        padding: 20px;
        background: #f9fbf9;
        border-right: 1px solid #e7e7e7;
        .title {
          margin-bottom: 10px;
          font-weight: bold;
        }
        p {
          font-size: 18px;
        }
      }
      .order-info-right {
        width: 600px;
        padding: 20px 40px;
        color: #979797;
        font-size: 18px;
        > div {
          display: flex;
          justify-content: space-between;
          padding: 3px 0;
          color: #606060;
        }
      }
    }
  }

  .add {
    width: 120px;
    margin: 20px 0;
    img {
      width: 100%;
    }
  }

  .order-table {
    margin-top: 40px;
  }

  .price-container {
    padding: 40px 0;
    color: #282828;
    font-size: 20px;
    text-align: right;
    p {
      padding: 5px 0;
    }
    .value {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>

<style lang="scss" scoped>
$goods-item-height: 118px;

.price {
  color: #d43a57;
}

.header {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #979797;
  padding: 10px 0 10px 20px;
  background: #f9fbf9;
  > div {
    flex: 1;
    text-align: center;
  }
}

.order-list {
  .order-item {
    margin-top: 20px;
    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      background: #f7fff7;
      font-size: 14px;
      color: #606060;
      padding: 0 20px;
      border: 1px solid #e7e7e7;
    }
    .item-content {
      display: flex;
      font-size: 14px;
      text-align: center;
      border-right: 1px solid #e7e7e7;
      border-bottom: 1px solid #e7e7e7;
      .item-goods {
        flex: 6.5;
        .item-goods-list {
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          .item-goods-flex {
            display: flex;
            align-items: center;
            height: $goods-item-height;
            > div {
              flex: 1;
            }
          }
        }

        :nth-last-child(1) {
          border-bottom: none;
        }
      }
      > div {
        flex: 1;
        border-left: 1px solid #e7e7e7;
        padding-top: 10px;
        .alignment {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          ::v-deep .el-button {
            white-space: normal;
          }
        }
        .order-status {
          height: $goods-item-height;
          padding: 10px 0;
          border-bottom: 1px solid #e7e7e7;
          display: flex;
          align-items: center;
          ::v-deep .el-button {
            white-space: normal;
          }
          ::v-deep .el-button + .el-button {
            margin: 0;
          }
        }
        .order-status:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.list-item {
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  text-align: initial;
  .item-img {
    width: 80px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
    border-radius: 8px;
    img {
      display: block;
      width: 100%;
    }
  }
  .item-text {
    max-width: 220px;
    position: relative;
    .item-name {
      font-size: 14px;
      color: #000;
      margin: 8px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      .goods-sn {
        display: inline-block;
        background: #fcbbbb;
        color: #ffffff;
        padding: 0 5px;
        border-radius: 6px;
        margin-right: 10px;
      }
    }
    .specs,
    .barCode {
      color: #979797;
      font-size: 12px;
      margin: 2px 0;
    }
  }
}
</style>
