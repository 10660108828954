<!--
 * @Author: 52203928+yingzilin@users.noreply.github.com
 * @LastEditTime: 2023-09-25 14:18:06
-->
<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="300px"
      title="Progreso del pedido"
    >
      <div class="main" v-loading="loading">
        <el-steps direction="vertical" :active="list.length">
          <el-step
            :title="item.name"
            v-for="(item, index) in list"
            :key="index"
            :description="item.createdTime"
          ></el-step>
        </el-steps>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      list: [],
      loading: false
    }
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (!val) {
          this.list = []
        }
      },
      immediate: true
    }
  },
  methods: {
    open(row) {
      const params = {
        bizId: row.id,
        bizType: row.aftersales ? 'AfterOrder' : 'Order'
      }
      this.getList(params)
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    },
    async getList(row) {
      this.loading = true
      const {
        data: { result }
      } = await this.$axios.post('/timerShaft/findDetail', row)
      this.list = result
      this.loading = false
      console.log('时间轴:>> ', result)
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  max-height: 500px;
}
</style>
